
import { computed, ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { getOrderStatusColor } from '@/utils/orderUtils'
import axios from '@/services/axios'
import { useNotification } from '@/composables'
import { formatterDollars } from '@/utils/formatter'
import { ORDER_STATUS } from '@/utils/constants'

export default {
  name: 'Refund',
  components: {
    sectionLayoutContent,
  },
  setup() {
    const { getOrder } = useOrderRepositories()
    const route = useRoute()
    const { success, error } = useNotification()
    const breadcrumbs = [
      {
        text: 'Orders',
        link: 'agency.orders',
        params: null,
      },
      {
        text: 'Detail',
        link: 'agency.orders.detail',
        params: null,
      },
      {
        text: 'Refund',
        link: '',
        params: null,
      },
    ]
    const content = computed(() => {
      return { breadcrumbs }
    })
    const data = ref<any>({})
    const listItems = ref<any>([])

    const quantity = ref(0)
    const refundShipping = ref(0)
    const totalDiscount = ref(0)
    const refundAmount = ref(0)
    const availableRefund = ref(0)
    const reason = ref('')

    const refundItems = computed(() => {
      return listItems.value.filter((item: any) => item.refundQty > 0)
    })

    const totalQuantity = computed(() => {
      let total = 0
      refundItems.value.map((item: any) => (total = total + item.refundQty))

      return total
    })
    const subtotal = computed(() => {
      let total = 0
      refundItems.value.map(
        (item: any) => (total = total + item.refundQty * item.price)
      )
      return total
    })

    const refundProvisional = computed(() => {
      return (
        subtotal.value +
        (refundShipping.value || 0) -
        (totalDiscount.value || 0)
      )
    })
    const grandTotal = ref(0)

    const setRefundAmount = () => {
      refundAmount.value = Number(refundProvisional.value.toFixed(2))
    }
    watch([refundProvisional], setRefundAmount)

    onMounted(async () => {
      await fetchData()
    })

    const fetchData = async () => {
      let res = await getOrder(route.params.id)
      if (res) data.value = res
      if (res.items) {
        listItems.value = res.items.map((item: any) => {
          const refundableList = [
            ORDER_STATUS.UNFULFILLED,
            ORDER_STATUS.IN_REVIEW,
            ORDER_STATUS.APPROVED,
            ORDER_STATUS.IN_PRODUCTION,
            ORDER_STATUS.IN_TRANSIT,
            ORDER_STATUS.FULFILLED,
            ORDER_STATUS.PARTIALLY_FULFILLED,
          ]
          let refundable = refundableList.includes(res.status)
          let fullRefunded = Boolean(item?.refundStatus === 'full_refunded')
          return {
            ...item,
            quantity: item.quantity - (item.refundQty || 0),
            refundQty: 0,
            refundable,
            fullRefunded,
          }
        })
        availableRefund.value = res.grandTotal - (res?.refundAmount || 0)
        grandTotal.value = res?.grandTotal || 0
      }
    }

    const submit = async () => {
      const body = {
        refundType: refundItems.value.length ? 'refund_item' : 'refund_money',
        orderId: route.params.id,
        items: refundItems.value,
        refundAmount: refundAmount.value,
        grandTotal: grandTotal.value,
        reason: reason.value,
      }
      // const resource = 'http://localhost:3002/api/orders'
      const resource = 'orders'
      const res = await axios.patch(
        `${resource}/refund/${route.params.id}`,
        body
      )
      if (res.status === 200) {
        success('Refund successfully')
        fetchData()
      } else error('Refund failed')
    }

    return {
      content,
      data,
      listItems,
      refundItems,
      reason,
      availableRefund,
      quantity,
      subtotal,
      refundShipping,
      totalQuantity,
      refundProvisional,
      grandTotal,
      fetchData,
      formatterDollars,
      getOrderStatusColor,
      submit,
      refundAmount,
      totalDiscount,
    }
  },
}
